<template>
  <section class="section home">
    <PayInfoModal v-if="showInfoModal" @checkout="
      showInfoModal = false;
    checkout();
    " />

    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title is-size-2 has-text-centered is-hidden-desktop">
            PCC's
            <br />
            <span class="has-text-success">“I’m Dreaming of a Green Christmas”</span>
            <br />
            Virtual 50-50 Raffle 
          </h1>
          <h1 class="title is-size-1  is-hidden-mobile">
            Preston Center of Compassion's
            <br />
            <span class="has-text-success">“I’m Dreaming of a Green Christmas”</span>
            <br />

            Virtual 50-50 Raffle 
          </h1>
          <h2 class="subtitle is-size-4">
            <span class="has-text-success">Drawing</span> on December 19, 2024
          </h2>
          <!-- <div class="notification is-success with-bow is-flex">
            <img src="../assets/bow.png" alt="" class="bow" />
            <div class="columns is-vcentered is-mobile">
              <div class="column is-narrow">
                <p class="is-size-1">
                  <strong class="has-text-danger">$</strong>
                </p>
              </div>
              <div class="column">
                All proceeds will be used for Scholarships to children for our
                daily after-school programs and Summer Recreation Program!
              </div>
            </div>
          </div> -->

          <p class="mb-2">
            PCC is hoping you can partner with us and consider partaking in our
            raffle and/or make an end of year donation as we strive to serve
            all in our Bronx Ministry!
          </p>
          <p class="mb-2"><strong>Not interested in the raffle and just want to donate? <a href="https://donate.stripe.com/3cscQc5f1b6T1EI15b" target="_blank">Donate here</a></strong></p>

          <span class="tag is-info is-rounded is-hidden-desktop raffle-total">~${{ raffleTotal }} raised</span>

          <!-- <div class="notification is-warning">
            <p>
              This 50-50 raffle has <strong>sold out</strong>! There are still
              tickets left for the <strong>COACH BAG BONUS RAFFLE</strong>.
            </p>

            <br />

            <router-link
              :to="{ name: 'BonusRaffle' }"
              class="button is-warning is-light"
              >Go to Bonus Raffle</router-link
            >
          </div> -->
        </div>
        <div class="column is-4">
          <div class="is-flex is-flex-direction-column is-align-items-center has-text-centered">
            <span class="tag is-info is-rounded  is-hidden-touch raffle-total">~${{ raffleTotal }} raised</span>
          </div>
        </div>
      </div>

      <a v-if="isAdmin" class="button" href="/bingo/administration">Administration</a>

      <div class="tabs" id="tabs">
        <ul>
          <li :class="{ 'is-active': tab === 'choose' }" @click="tab = 'choose'">
            <a>Purchase Bingo Balls</a>
          </li>
          <li :class="{ 'is-active': tab === 'old' }" @click="tab = 'old'">
            <a>View Your Purchased Bingo Balls
              <span class="tag is-primary" style="margin-left: 5px">{{
                oldSelections.length
              }}</span></a>
          </li>
        </ul>
      </div>

      <div v-if="tab == 'choose'" class="choose-new-bingo-balls">
        <div class="box  ">
          <div class="columns selections-display is-vcentered">
            <div v-if="selectedCount > 0" class="column is-narrow ">
              <div class="buttons">
                <button v-if="selectedCount > 0" class="button is-large is-success"
                  :class="{ 'is-loading': isCheckoutLoading }" :disabled="isCheckoutLoading"
                  @click="showInfoModal = true">
                  Purchase {{ newSelections.length }} for ${{ totalCost }}
                </button>
              </div>
            </div>
            <div v-else class="column">
              <h1 class="title is-size-4">
                Choose your bingo balls from the trays below for $10 each!
              </h1>
            </div>
            <div v-if="newSelections.length > 0" class="column tray-selections">
              <BingoBall v-for="selection in newSelections" :key="selection.trayId + selection.number"
                :number="selection.number" :trayId="selection.trayId" @select="unselectBall" />
            </div>
          </div>
          <span class="has-text-grey">There are {{ 13 - trays.length }}+ more trays on deck!</span>
        </div>

        <div class="columns is-multiline is-centered mt-4">
          <div v-for="tray in trays" :key="tray.name" class="tray column is-narrow">
            <h2 class="title is-size-3">
              {{ tray.name }} Tray
              <span class="tag is-danger">{{ ballsLeftInTray(tray.id) }} left</span>
            </h2>
            <h3 class="subtitle is-hidden-mobile">
              Click on a ball to select it!
            </h3>
            <h3 class="subtitle is-hidden-tablet">
              Tap on a ball to select it!
            </h3>
            <div class="columns is-mobile is-variable is-0-mobile tray-balls">
              <p v-if="ballsLeftInTray(tray.id) === 0" class="cover has-text-centered">
                This tray has sold out!
              </p>
              <div v-for="(letter, index) in 'BINGO'" :key="letter" class="column"
                style="display: flex; flex-direction: column;">
                <BingoBall v-for="n in 15" :key="n" :trayId="tray.id" :number="15 * index + n"
                  :disabled="!!isSelected(tray.id, 15 * index + n)" @select="selectBall" />
              </div>
            </div>
            <hr />
          </div>
        </div>

        <div class="box  ">
            <div class="columns selections-display is-vcentered">
              <div v-if="selectedCount > 0" class="column is-narrow ">
                <div class="buttons">
                  <button v-if="selectedCount > 0" class="button is-large is-success"
                    :class="{ 'is-loading': isCheckoutLoading }" :disabled="isCheckoutLoading"
                    @click="showInfoModal = true">
                    Purchase {{ newSelections.length }} for ${{ totalCost }}
                  </button>
                </div>
              </div>
              <div v-else class="column">
                <h1 class="title is-size-4">
                  Choose your bingo balls from the trays below for $10 each!
                </h1>
              </div>
              <div v-if="newSelections.length > 0" class="column tray-selections">
                <BingoBall v-for="selection in newSelections" :key="selection.trayId + selection.number"
                  :number="selection.number" :trayId="selection.trayId" @select="unselectBall" />
              </div>
            </div>
            <span class="has-text-grey">There are {{ 13 - trays.length }}+ more trays on deck!</span>
          </div>

        <a href="#" class="button is-link">Back to Top</a>
      </div>
      <div v-else-if="tab === 'old'" class="your-bingo-balls">
        <BingoBall v-for="selection in oldSelections" :key="selection.trayId + selection.number"
          :number="selection.number" :trayId="selection.trayId" />

        <p v-if="oldSelections.length === 0">
          You have not paid for any bingo balls yet!
        </p>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': isDonateModalOpen }">
      <div class="modal-background" @click="isDonateModalOpen = false" />
      <div class="modal-content">
        <div class="box" id="donate">
          <form @submit.prevent="customDonationCheckout" class="field">
            <label class="label">
              Don't want to participate in the raffle? Would you
              <strong>consider</strong> making a <strong>Donation</strong> for
              scholarships for our programs instead? You can choose the amount.
            </label>
            <img src="../assets/mission.png" alt="" />
            <div class="field-body">
              <div class="field has-addons">
                <p class="control">
                  <a href="#" class="button is-static">$</a>
                </p>
                <div class="control is-expanded">
                  <input class="input" type="number" v-model="customDonationAmount" min="10" max="5000"
                    placeholder="Enter amount" required />
                </div>
              </div>

              <div class="field">
                <div class="is-flex">
                  <button class="button is-link is-fullwidth" :class="{ 'is-loading': isCheckoutLoading }"
                    :disabled="isCheckoutLoading">
                    Donate ${{ customDonationAmount }}
                  </button>
                  <button type="button" @click="isDonateModalOpen = false" class="button is-warning">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { db, functions } from "@/firebase";
import firebase from "firebase/app";

import PayInfoModal from "@/components/PayInfoModal.vue";
import BingoBall from "@/components/BingoBall.vue";

export default {
  name: "Bingo",
  components: {
    BingoBall,
    PayInfoModal
  },
  data() {
    return {
      tab: "choose",
      showInfoModal: false,
      customDonationAmount: 100,
      isDonateModalOpen: false,
      isCheckoutLoading: false,
      trays: [],
      selections: []
    };
  },
  firestore: {
    trays: db.collection("trays").orderBy("order"),
    selections: db.collection("selections").orderBy("addedAt", "desc")
  },
  computed: {
    raffleTotal() {
      return this.selections.filter(sel => sel.paid === true).length * 10;
    },
    user() {
      return this.$store.state.auth.user;
    },
    userName() {
      return this.$store.getters.userName;
    },
    userEmail() {
      return this.$store.state.auth.user.profile.email;
    },
    isAdmin() {
      return [
        "thefrankmatranga@gmail.com",
        "frank@matranga.family",
        "warnerrdc@aol.com",
        "melenzana@aol.com",
        "pwarner@prestoncenterofcompassion.org",
        "operations@prestoncenterofcompassion.org",
        "finance@prestoncenterofcompassion.org"
      ].includes(this.userEmail)
    },
    selectedCount() {
      return this.newSelections.length;
    },
    newSelections() {
      return this.selections.filter(
        sel => sel.user === this.userEmail && !sel.paid
      );
    },
    oldSelections() {
      return this.selections.filter(
        sel => sel.user === this.userEmail && sel.paid
      );
    },
    soldOutBatches() {
      return [];
    },
    totalCost() {
      return Object.keys(this.newSelections).length * 10;
    }
  },
  methods: {
    ballsLeftInTray(trayId) {
      return (
        75 -
        this.selections.filter(sel => sel.trayId === trayId && sel.paid).length
      );
    },
    isSelected(trayId, number) {
      return this.selections.find(
        sel => sel.trayId === trayId && sel.number === number
      );
    },
    selectBall({ trayId, number }) {
      if (this.isSelected(trayId, number)) return;
      if (this.isCheckoutLoading) return;

      db.collection("selections").add({
        trayId,
        number,
        user: this.userEmail,
        addedAt: firebase.firestore.FieldValue.serverTimestamp(),
        paid: false
      });
    },
    unselectBall({ trayId, number }) {
      if (this.isCheckoutLoading) return;

      const ball = this.isSelected(trayId, number);
      if (!ball || ball.paid || ball.user !== this.userEmail) return;
      db.collection("selections")
        .doc(ball.id)
        .delete();
    },
    async checkout() {
      if (this.isCheckoutLoading) return;
      this.isCheckoutLoading = true;

      const { data } = await functions.httpsCallable("bingoCheckout")({
        email: this.userEmail
      });

      if (data.error) {
        console.error(data.error);
        alert(data.error);
      } else if (data.session) {
        this.$store.state.stripe.stripe.redirectToCheckout({
          sessionId: data.session.id
        });
      }
    },
    async customDonationCheckout() {
      // Prevent double checkout
      if (this.isCheckoutLoading) return;
      this.isCheckoutLoading = true;

      // Make call to checkout Firebase function
      const { data } = await functions.httpsCallable("bingoDonationCheckout")({
        donationAmount: this.customDonationAmount
      });

      // Handle error or success
      if (data.error) {
        console.error(data.error);
        alert(data.error);
      } else if (data.session) {
        // Redirect to checkout page on success
        this.$store.state.stripe.stripe.redirectToCheckout({
          sessionId: data.session.id
        });
      }

      this.isCheckoutLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.selections-display {
  position: sticky;
  top: 10px;
  z-index: 10;
  background-color: white;
  padding: 0.5rem;
}

.tray-balls {
  position: relative;
}

.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.384);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
}

.tray-balls {
  overflow-x: auto;
}

.tray-selections {
  overflow-y: auto;
  max-height: 13rem;
}

.raffle-total {
  font-size: 2rem;
  // font-family: monospace;
}

.mike-img {
  max-width: 250px;
  border-radius: 10px;
}

.with-bow {
  position: relative;

  .bow {
    position: absolute;
    top: -30px;
    right: -30px;
    width: 100px;
  }
}
</style>
