<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="box">
        <div id="firebase-ui-auth-container"></div>
        <div v-if="sentEmail">
          <p class="is-size-4 has-text-centered">
            Check your inbox at <strong>{{ email }}</strong> for the sign-in
            link from noreply@pcc-fundraising.org!
          </p>
          <p class="mt-2  has-text-centered">(If not in inbox, check <strong>your spam/junk folder</strong>. Email <a href="mailto:frank@matranga.dev">frank@matranga.dev</a> for help.)</p>
        </div>
        <form v-else @submit.prevent="sendSignInLink">
          <div class="field">
            <label v-if="isPrefilled" for="email" class="label">Please confirm your email address then click the sign-in button:</label>
            <label v-else for="email" class="label">What is your email address?</label>
            <div class="control">
              <input
                name="email"
                type="email"
                class="input"
                v-model="email"
                placeholder="Enter your email address"
                required
              />
            </div>
          </div>

          <button class="button is-primary">Send Sign-in Link</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "../firebase";

export default {
  data() {
    return {
      email: this.$route.query.email ?? '',
      sentEmail: false
    };
  },
  computed: {
    isPrefilled() {
      return !!this.$route.query.email;
    }
  },
  methods: {
    sendSignInLink() {
      auth
        .sendSignInLinkToEmail(this.email, {
          url: window.location.href,
          // location.hostname === "localhost"
          //   ? "http://localhost:8080"
          //   : "https://pcc-fundraising.web.app",
          handleCodeInApp: true
        })
        .then(() => {
          this.sentEmail = true;
          window.localStorage.setItem("emailForSignIn", this.email);
        })
        .catch(err => {
          alert(err);
          console.error(err);
        });
    }
  }
};
</script>

<style></style>
